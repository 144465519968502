<template>
  <div class="row" :class="classesList">
    <slot/>
  </div>
</template>

<script>
export default {
  props:{
    noGutters: {},
    alignV: {
      type: String
    },
    alignH: {
      type: String
    },
    alignContent:{
      type: String
    }
  },
  setup(props){
    let classesList = [];
    if(props.noGutters){
      classesList.push('no-gutters');
    }
    if(props.alignV){
      classesList.push(`align-items-${props.alignV}`)
    }
    if(props.alignH){
      classesList.push(`justify-content-${props.alignH}`)
    }
    if(props.alignContent){
      classesList.push(`align-content-${props.alignContent}`)
    }
    return {
      classesList
    }
  }
}
</script>
