import BlockContent from "./main-block/components/BlockContent";
import BlockHead from "./main-block/components/BlockHead";
import Column from "./col/Col";
import FormGroup from "./form-group/FormGroup";
import MainBlock from "./main-block/MainBlock";
import Row from "./row/Row";

export {
    BlockContent,
    BlockHead,
    Column,
    FormGroup,
    MainBlock,
    Row,
}
