<template>
  <div class="user-card">
    <div class="user-avatar" :class="classesList">
      <img v-if="image" :src="image" alt="Avatar"/>
      <span v-else class="text-uppercase">{{ initials }}</span>
    </div>
    <div class="user-info">
      <span class="lead-text tb-lead">{{ userName }} <span class="dot dot-success d-md-none ml-1"></span></span>
      <span v-if="userEmail">{{ userEmail }}</span>
      <slot name="more_info"></slot>
    </div>
    <div class="user-action" v-if="hasActionsSlot">
      <div class="dropdown">
        <a class="btn btn-icon btn-trigger mr-n2" data-toggle="dropdown" href="#"><em class="icon ni ni-more-v"></em></a>
        <div class="dropdown-menu dropdown-menu-right">
          <ul class="link-list-opt no-bdr">
            <slot name="user_actions"></slot>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  props: {
    initials: String,
    image: String,
    bgColor: {
      type: String,
      default: 'primary',
    },
    bgDim: Boolean,
    userEmail: String,
    userName: {
      type: String,
      required: true,
    }
  },
  setup(props, {slots}){

    let classesList = []
    if( props.image){
      classesList.push(`bg-transparent`)
    }
    else{
      if( props.bgDim ){
        classesList.push(`bg-dim-${props.bgColor}`)
      }
      else {
        classesList.push(`bg-${props.bgColor}`)
      }
    }

    let hasActionsSlot = ref(false)
    hasActionsSlot.value = slots.user_actions && slots.user_actions().length

    return {
      classesList,
      hasActionsSlot,
    }
  }
}
</script>
