<template>
  <table class="table table-ulogs">
    <thead class="thead-light">
    <tr>
      <th class="tb-col-os"><span class="overline-title">Browser <span class="d-sm-none">/ IP</span></span></th>
      <th class="tb-col-ip"><span class="overline-title">IP</span></th>
      <th class="tb-col-time"><span class="overline-title">Time</span></th>
      <th class="tb-col-action"><span class="overline-title">Event</span></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="log in logs" :key="log.id">
      <td class="tb-col-os">{{ log.user_agent }}</td>
      <td class="tb-col-ip"><span class="sub-text">{{ log.ip }}</span></td>
      <td class="tb-col-time"><span class="sub-text">{{ log.created_at }}</span></td>
      <td class="tb-col-action">{{ log.activity_type }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import axios from "@/libs/axios";
import {useStore} from "vuex"

export default {
  name: "ContentActivityLog",
  setup(){

    const store = useStore()

    let logs = ref([])
    const getActivityLog = async () => {
      if(logs.value.length){
        return true;
      }
      const endpoint = store.state.auth.user_role === 'is-advisor' ? 'advisor' : 'admin'
      axios.post('/api/'+endpoint+'/activity_log', {})
      .then(resp => {
        if(resp.data.success){
          for(let x in resp.data.data){
            logs.value.push(resp.data.data[x])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    }

    onBeforeMount(()=> getActivityLog())

    return {
      logs,
    }

  }
}
</script>
