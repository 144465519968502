<template>
  <card no-padding="">
    <div class="card-aside-wrap">
      <div class="card-inner card-inner-lg">
        <slot name="contentWrapper"></slot>
      </div>
      <div class="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg"
           :class="{'content-active': asideActive}"
           data-toggle-screen="lg" data-toggle-overlay="true">
        <div class="card-inner-group" data-simplebar>
          <slot name="aside"></slot>
        </div>
      </div>
      <div class="toggle-overlay" v-if="asideActive" v-on:click="toggleAside"></div>
    </div>
  </card>
</template>

<script>
import Card from "@core/components/cards/Card";
export default {
  name: "PageLayout",
  components: {Card},
  props: {
    asideActive: Boolean,
  },
  emits: ['toggleSidebar'],
  setup( props, {emit}){

    const toggleAside = () => emit('toggleSidebar')
    return {
      toggleAside,
    }
  }
}
</script>
