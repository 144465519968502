<template>
  <card>
    <template v-slot:grouped>
      <div class="card-inner">
        <div class="between-center flex-wrap flex-md-nowrap g-3">
          <div class="nk-block-text">
            <h6>Save my Activity Logs</h6>
            <p>You can save your all activity logs including unusual activity detected.</p>
          </div>
          <div class="nk-block-actions">
            <ul class="align-center gx-3">
              <li class="order-md-last">
                <div class="custom-control custom-switch mr-n2">
                  <input type="checkbox" class="custom-control-input" checked="" id="activity-log">
                  <label class="custom-control-label" for="activity-log"></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-inner">
        <div class="between-center flex-wrap g-3">
          <div class="nk-block-text">
            <h6>Change Password</h6>
            <p>Set a unique password to protect your account.</p>
          </div>
          <div class="nk-block-actions flex-shrink-sm-0">
            <ul class="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
              <li class="order-md-last">
                <nk-button type="primary" v-on:click="setModalOpenStatus(true)">Change Password</nk-button>
              </li>
              <li v-if="passwordLastChanged.password_updated_at">
                <em class="text-soft text-date fs-12px">Last changed: <span>{{ passwordLastChanged.password_updated_at }}</span></em>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </card>

  <ion-modal
      :is-open="isModalOpenRef"
      @didDismiss="setModalOpenStatus(false)"
  >
    <i-modal title="Change your password" @modal-close="setModalOpenStatus(false)">
      <div style="max-width: 350px;" class="m-auto">
        <form-group>
          <label class="overline-title" for="c_password">Current Password</label>
          <b-input type="password" v-model="currentPassword" id="c_password"></b-input>
        </form-group>
        <form-group>
          <label class="overline-title" for="new_pwd">New Password</label>
          <b-input type="password" v-model="newPassword" id="new_pwd"></b-input>
        </form-group>
        <form-group>
          <label class="overline-title" for="r_new_pwd">Re-enter Password</label>
          <b-input type="password" v-model="reNewPassword" id="r_new_pwd"></b-input>
        </form-group>
        <div class="mt-3">
          <ion-spinner v-if="pwdIsUpdating"></ion-spinner>
          <nk-button type="dark" v-else v-on:click="changePassword">Save Password</nk-button>
        </div>
      </div>
    </i-modal>
  </ion-modal>

</template>

<script>
import Card from "@core/components/cards/Card";
import NkButton from "@core/components/button/NkButton";
import {IonModal, toastController, alertController } from "@ionic/vue"
import IModal from "@core/components/ion-modal/IModal";
import {ref} from "vue";
import FormGroup from "@core/layouts/form-group/FormGroup";
import BInput from "@core/components/bp-form/components/BInput";
import axios from "@/libs/axios";
import {useStore} from "vuex"

export default {
  name: "ContentSecuritySettings",
  components: {BInput, FormGroup, NkButton, Card, IonModal, IModal},
  setup(){

    let isModalOpenRef = ref(false)
    const setModalOpenStatus = (status) => isModalOpenRef.value = status

    let pwdIsUpdating = ref(false)

    let currentPassword = ref('')
    let newPassword = ref('')
    let reNewPassword = ref('')

    const store = useStore()
    let passwordLastChanged = ''
    if(store.state.auth.user_role === 'is-advisor'){
      passwordLastChanged = store.state.advisor_data
    }
    else{
      passwordLastChanged = store.state.admin_data
    }

    const changePassword = async () => {
      const alertB = await alertController.create({buttons: ['OK']})
      let error = ''
      if(!currentPassword.value.length){
        error = "- Please provide your current password.<br/>";
      }
      if(newPassword.value.length < 8){
        error += "- New password must be at least 8 character long.<br/>"
      }
      if(newPassword.value !== reNewPassword.value){
        error += '- Both password must match.'
      }
      if(error.length){
        alertB.message = error
        await alertB.present()
        return false
      }
      pwdIsUpdating.value = true
      const toast = await toastController.create({color: "success", duration: 3000})
      const url = store.state.auth.user_role === 'is-advisor' ? '/api/advisor/change_password' : '/api/admin/account/change_password'
      axios.post(url, {c_password: currentPassword.value, password: newPassword.value})
      .then(resp => {
        if(resp.data.success){
          toast.message = 'Thank you! password successfully updated'
          toast.present()
          newPassword.value = reNewPassword.value = currentPassword.value = ''
        }
        else{
          alertB.message = resp.data.message
          alertB.present()
        }
      })
      .catch(error => {
        alertB.message = 'Error: '+error.response.status+' '+error.response.statusText
        alertB.present()
      })
      .then( () => pwdIsUpdating.value = false)
    }

    return {
      isModalOpenRef,
      setModalOpenStatus,
      pwdIsUpdating,
      currentPassword,
      newPassword,
      reNewPassword,
      changePassword,
      passwordLastChanged,
    }
  },
}
</script>
