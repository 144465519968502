<template>
  <div :class="classesList">
    <slot></slot>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "Column",
  props: {
    default: undefined,
    sm: undefined,
    md: undefined,
    lg: undefined,
    xl: undefined,
    xxl: undefined,
    auto: undefined,
  },
  setup(props){

    const classesList = computed(()=>{
      let classes = []
      if(undefined !== props.auto){
        classes.push('col-auto')
      }
      if(undefined !== props.default){
        classes.push(`col-${props.default}`)
      }
      for(let x in props){
        if(x === 'auto' || x === 'default'){
          continue
        }
        if(!props[x]){
          continue;
        }
        classes.push(`col-${x}-${props[x]}`)
      }
      return classes
    })
    return{
      classesList
    }
  }
}
</script>
