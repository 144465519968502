<template>
  <div class="nk-data data-list">
    <div class="data-head" v-if="undefined !== title">
      <h6 class="overline-title">{{ title }}</h6>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: undefined,
  },
}
</script>