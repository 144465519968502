<template>
  <div class="nk-block-head nk-block-head-lg">
    <div class="nk-block-between">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title">{{ title }}</h4>
        <div class="nk-block-des">
          <p>{{ description }}</p>
        </div>
      </div>
      <div class="nk-block-head-content align-self-start d-lg-none">
        <a href="javascript:;" class="toggle btn btn-icon btn-trigger mt-n1" v-on:click="toggleSidebar"><em class="icon ni ni-menu-alt-r"></em></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsContentHeader",
  props: {
    title: String,
    description: String,
  },
  emits: ['toggleSidebar'],
  setup(props, {emit}){
    const toggleSidebar = () => {
      emit('toggleSidebar')
    }

    return {
      toggleSidebar,
    }
  }
}
</script>
