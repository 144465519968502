<template>
  <div class="card-inner-group" data-simplebar>
    <div class="card-inner">
      <user-small-card
          :user-name="userData.first_name+' '+userData.last_name"
          :user-email="userData.email"
          :image="userData.dp"
          :initials="userData.initials">
        <template v-slot:user_actions>
          <li><a href="#"><em class="icon ni ni-camera-fill"></em><span>Change Photo</span></a></li>
          <li><a href="javascript:;" v-on:click="openProfileUpdateModal"><nio-icon icon="ni-edit-fill"></nio-icon><span>Update Profile</span></a></li>
        </template>
      </user-small-card>
    </div>
    <div class="card-inner p-0">
      <ul class="link-list-menu">
        <li v-for="(tab,tIndex) in tabs" :key="tIndex" :class="{active: (currentTab === tIndex)}">
          <a href="javascript:;" v-on:click="switchTab(tIndex)">
            <nio-icon :icon="tab.icon"></nio-icon>
            <span>{{ tab.title}}</span></a>
        </li>
      </ul>
    </div>
    <div class="card-inner" v-if="userData.last_login">
      <h6 class="overline-title-alt">Last login</h6>
      <p>{{ userData.last_login }}</p>
    </div>
  </div>
</template>

<script>
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import store from "@/store"
import NioIcon from "@core/components/nio-icon/NioIcon";

export default {
  name: "SettingsSidebar",
  components: {NioIcon, UserSmallCard},
  props:{
    userName: String,
    currentTab: {
      default: 'p_info',
    },
    tabs: {
      required: true,
      type: Object,
    },
  },
  emits: ['switchTab'],
  setup( props, {emit} ){

    let userData = {}
    if(store.state.auth.user_role === 'is-advisor'){
      userData = store.state.advisor_data
    }
    else{
      userData = store.state.admin_data
    }
    const switchTab = ( toTab ) => emit('switchTab', toTab)

    const openProfileUpdateModal = () => {
      store.dispatch('settings/open_profile_update_popup', true)
    }

    return {
      userData,
      switchTab,
      openProfileUpdateModal,
    }
  }
}
</script>
