import {alertController, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";

export default (store) => {

    const updateProfile = async (data) => {

        const loader = await loadingController.create({message: 'Updating...'})
        await loader.present()

        const alertB = await alertController.create({buttons: ["OK"] });
        const toast  = await toastController.create({duration:3000})

        const endpoint = store.state.auth.user_role === 'is-advisor' ? 'advisor/update_profile' : 'admin/account/update_profile'
        axios.post('/api/'+endpoint, data)
            .then(resp => {
                if(!resp.data.success){
                    alertB.message = resp.data.message
                    alertB.present()
                }
                else{
                    if(store.state.auth.user_role === 'is-advisor'){
                        store.commit('commit_advisor_data', data)
                    }
                    else{
                        store.commit('commit_admin_data', data)
                    }
                    toast.message = 'Thank you! profile updated.'
                    toast.present()
                }
            })
            .catch( error => {
                alertB.message = 'Error: '+error.response.status+' '+error.response.statusText
                alertB.present()
            })
            .then(()=> loader.dismiss())
    }

    return {
        updateProfile,
    }
}